import React, { useState } from "react";
import { graphql, Link, navigate, StaticQuery } from "gatsby";
import { Grid, Image, Card, Modal, Button } from "semantic-ui-react";
import HeaderComponent from "../common/header";
import ContactForm from "../common/contactForm";
import "../../styles/productMain.css";

const ProductMain = ({ product }) => {
  const [mainImage, setMainImage] = useState(product.coverImage.fluid.src);
  const [modalOpen, setModalOpen] = useState(false);
  const [sampleModalOpen, setSampleModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const openSampleModal = () => setSampleModalOpen(true);
  const closeSampleModal = () => setSampleModalOpen(false);

  const handleBack = () => {
    if (product.seriesSlug) {
      navigate(`/series/${product.seriesSlug}`);
    } else {
      navigate(-1);
    }
  };

  return (
    <StaticQuery
      query={graphql`
        query RelatedProductsQuery {
          allContentfulProducts {
            nodes {
              slug
              name
              lookTags
              typeTags
              seriesSlug
              productCatalogFile {
                file {
                  url
                }
              }
              technicalData {
                fluid(maxWidth: 4000, quality: 100) {
                  src
                }
              }
              modelNumber
              coverImage {
                fluid(maxWidth: 1920) {
                  src
                }
              }
              colors {
                id
                fluid(maxWidth: 1920) {
                  src
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const products = data.allContentfulProducts.nodes;

        let relatedProducts = [];
        if (products && product.relatedProductModels) {
          relatedProducts = products.filter((p) =>
            product.relatedProductModels.includes(p.modelNumber)
          );
        }

        return (
          <div className="product-detail-page">
            <HeaderComponent page="products" />

            <Grid className="product-main">
              <Grid.Row columns={1} only="mobile">
                <Grid.Column className="product-banner-left">
                  <Image
                    src={mainImage}
                    className="product-main-image"
                    onClick={openModal}
                  />
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', flexWrap: 'wrap' }}>
                    {[product.coverImage, ...product.productImages].map(image => (
                      <img
                        key={image.id}
                        src={image.fluid.src}
                        className="thumbnail-image"
                        onClick={() => setMainImage(image.fluid.src)}
                      />
                    ))}
                  </div>
                </Grid.Column>
                <Grid.Column className="product-banner-right">
                  <h1>{product.name}</h1>
                  <p>{product.modelNumber}</p>
                  <p className="description">{product.description.description}</p>
                  <div className="buttonContainer">
                    <a onClick={handleBack} className="backToAllButton">Back to Series</a>
                    <a onClick={openSampleModal} className="requestSampleButton">Request Sample</a>
                  </div>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2} only="tablet computer">
                <Grid.Column className="product-banner-left">
                  <Image
                    src={mainImage}
                    style={{ width: "500px", height: "500px" }}
                    className="product-main-image"
                    onClick={openModal}
                  />
                  <div style={{ display: 'flex', justifyContent: 'left', marginTop: '10px', flexWrap: 'wrap' }}>
                    {[product.coverImage, ...product.productImages].map(image => (
                      <img
                        key={image.id}
                        src={image.fluid.src}
                        className="thumbnail-image"
                        onClick={() => setMainImage(image.fluid.src)}
                      />
                    ))}
                  </div>
                </Grid.Column>
                <Grid.Column className="product-banner-right">
                  <h1>{product.name}</h1>
                  <p>{product.modelNumber}</p>
                  <p className="description">{product.description.description}</p>
                  <div className="buttonContainer">
                    <a onClick={handleBack} className="backToAllButton">Back to Series</a>
                    <a onClick={openSampleModal} className="requestSampleButton">Request Sample</a>
                  </div>
                </Grid.Column>
              </Grid.Row>

              {product.technicalData?.fluid?.src && (
                <Grid.Row className="technical-data">
                  <div className="technical-data-image">
                    <img
                      src={product.technicalData.fluid.src}
                      alt="Technical Specifications"
                      style={{ maxWidth: '80%', marginBottom: '20px', marginLeft: '10%', marginRight: '10%' }}
                    />
                    <a href={product.technicalData.fluid.src} download target="_blank" rel="noopener noreferrer" className="download-tech-spec-button">Download Tech Specs</a>
                  </div>
                </Grid.Row>
              )}

              {product.productCatalogFile?.file && (
                <Grid.Row className="designerpick">
                  <div className="designerpick-right">
                    <embed
                      src={product.productCatalogFile.file.url}
                      type="application/pdf"
                      width="100%"
                      height="600px"
                    />
                    <a
                      href={product.productCatalogFile.file.url}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                      className="downloadButton"
                    >
                      Download Catalog
                    </a>
                  </div>
                </Grid.Row>
              )}

              <Grid.Row className="products-list" style={{ padding: "0 7.3%" }}>
                <div className="mobile-divider-container">
                  <div className="left bar"></div>
                  <div className="header">You May Also Like</div>
                  <div className="right bar"></div>
                </div>
                {relatedProducts.map((product) => {
                  const link = `/products/${product.slug}`;
                  return (
                    <Link to={link} key={product.slug}>
                      <Card>
                        <Image src={product.coverImage.fluid.src} wrapped ui={false} className="coverImage" />
                        <Card.Content>
                          <Card.Header>
                            {product.name}
                            <span style={{ float: "right" }}>{product.modelNumber}</span>
                          </Card.Header>
                          <Card.Description>
                            <div>
                              {product.colors.map((color) => (
                                <span className="color-circle" key={color.id}>
                                  <Image src={color.fluid.src} />
                                </span>
                              ))}
                            </div>
                          </Card.Description>
                        </Card.Content>
                      </Card>
                    </Link>
                  );
                })}
              </Grid.Row>
            </Grid>

            {/* Image Modal */}
            <Modal open={modalOpen} onClose={closeModal} className="full-screen-modal" closeIcon>
              <Modal.Content>
                <Image
                  src={mainImage}
                  alt="Enlarged view"
                  style={{ width: "100%" }}
                />
              </Modal.Content>
            </Modal>

            {/* Sample Request Modal */}
            <Modal open={sampleModalOpen} onClose={closeSampleModal} size="large" className="full-screen-modal requestsample" closeIcon>
              <Modal.Header>Request Sample: {product.name}</Modal.Header>
              <Modal.Content className="modal-content">
                <ContactForm subject={`Request Sample - ${product.name}`} />
              </Modal.Content>
            </Modal>
          </div>
        );
      }}
    />
  );
}

export default ProductMain;
