import React from "react"
import { graphql } from "gatsby";
import ProductBanner from "./productBanner"
import ProductMain from "./productMain"
import Footer from "../common/footer"
import "semantic-ui-css/semantic.min.css"
import "../../styles/index.css"

const ProductDetail = ({ data }) => {
  const product = data.contentfulProducts

  return (
    <div style={{background:'#F2F2F2'}}>
      {/* <ProductBanner product={product}/> */}
      <ProductMain product={product}/>
      <Footer />
    </div>
  )
}
export default ProductDetail

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulProducts(slug: { eq: $slug }) {
      slug
      name
      modelNumber
      seriesSlug
      coverImage {
        fluid (maxWidth: 4000,quality:100){
          src
        }
      }
      productImages {
        id
        fluid (maxWidth: 4000,quality:100){
          src
        }
      }
      productCatalogFile {
        file {
          url
        }
      }
      technicalData {
        id
        fluid (maxWidth: 4000,quality:100){
          src
        }
      }
      description {
        description
      }
      sizeImage {
        fluid (maxWidth: 4000,quality:100){
          src
        }
      }
      collectionTitle
      collectionParagraph {
        collectionParagraph
      }
      collectionImage {
        fluid (maxWidth: 4000,quality:100){
          src
        }
      }
      collectionVideo {
        file {
          url
        }
      }
      colors {
        id
        fluid (maxWidth: 4000,quality:100){
          src
        }
      }
      relatedProductModels
    }
  }
`;